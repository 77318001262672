*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
  background-color:#3F4E4F;
} 

body{
  width: 100%;
  max-width: 1400px;
  display:block;
  margin:auto;
  font-family:Arial, Helvetica, sans-serif;
}

